<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>Music Bingo</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- Toolbar-->
    

    <f7-row no-gap>
      <f7-col width="75">
        <div class="bingoGrid">
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="1"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="2"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="3"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="4"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="5"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="6"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="7"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="8"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="9"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="10"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="11"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="12"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="13"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="14"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="15"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="16"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="17"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="18"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="19"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="20"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="21"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="22"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="23"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="24"></div>
          <div class="bcell" @click="sectionClick($event)" data-checked="0" data-mid="25"></div>
        </div>
      </f7-col>
      <f7-col width="25">
        <f7-button fill style="border-radius:0px;">Instructions</f7-button>
        <f7-block-title>Songs Played</f7-block-title>
        <f7-list media-list style="margin:0!important;">
          <f7-list-item 
            title="Billie Jean"
            subtitle="Michael Jackson"
            data-title="Billie Jean"
            data-artist="Michael Jackson"
            @click="musicInfo($event)"
          >
            <template #media>
              <img src="https://cdn.framework7.io/placeholder/fashion-88x88-3.jpg" width="44" />
            </template>
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
  </f7-page>
</template>
<script>
  import { f7 } from 'framework7-vue';
  import store from '../js/store.js';
  import Dom7 from 'dom7';

  export default {
    
    props: {
      f7router: Object,
    },
    mounted(){

    },
    methods: {
      sectionClick($event){
        var $$ = Dom7;
        var checked = $$($event.srcElement).attr('data-checked');
        var newColor = (checked=='1') ? '#FFF' : '#CCC' ;
        $$($event.srcElement).attr('data-checked',(checked=='1')?'0':'1');
        $$($event.srcElement).css('background',newColor);
      },
      musicInfo($event){
        console.log($event);
       var $$ = Dom7;
       const self = this;
       const title = $$($event.srcElement).closest('.media-item').attr('data-title');
       const artist = $$($event.srcElement).closest('.media-item').attr('data-artist');
        // Create popup
        if (!self.popup) {
          self.popup = f7.popup.create({
            content: `
              <div class="popup">
                <div class="page">
                  <div class="navbar">
                    <div class="navbar-bg"></div>
                    <div class="navbar-inner">
                      <div class="title">Song Details</div>
                      <div class="right"><a href="#" class="link popup-close">Close</a></div>
                    </div>
                  </div>
                  <div class="page-content">
                    <div class="block">
                      <p>Title: `+title+`</p>
                      <p>Artist: `+artist+`</p>
                      <p>We can add any other information, links or buttons we want on this popup.</p>
                    </div>
                  </div>
                </div>
              </div>
            `.trim(),
          });
        }
        // Open it
        self.popup.open(); 
      }
    }
  }
</script>